import isPlainObject from "lodash/isPlainObject";
import baseIsEqual from "lodash/isEqual";

export const isEmpty = (value?: unknown) =>
  value === null ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0) || // handle empty array
  (typeof value === "string" && value.trim().length === 0) ||
  (isPlainObject(value) && Object.keys(value).length === 0);

export const isEqual = (value: unknown, other: unknown) => {
  if (
    (value === undefined || value === null) &&
    (other === undefined || other === null)
  ) {
    // No need strict compare for nullish fields as they
    // are the same semantically.
    return true;
  }
  return baseIsEqual(value, other);
};

export const isValidJSON = (text: string): boolean => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const isEmptyObject = (value?: unknown) =>
  value === null ||
  value === undefined ||
  (typeof value === "object" && Object.values(value).every((v) => isEmpty(v)));
