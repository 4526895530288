/**
 * Uppercase for each word
 */
export const segmentEvents = {
  loginFailed: "Login Failed",
  loginSucceeded: "Login Completed",
  contactUsClicked: "Registration Contact Clicked",
  forgotPasswordStarted: "Forgot Password Started",
  forgotPasswordCompleted: "Forgot Password Completed",
  languageSet: "Language Set",
  logout: "User Logout",
  pageViewed: "[Portal] Page Viewed",
  registrationStarted: "Registration Started",
  registrationSucceeded: "Registration Completed",
  registrationLinkExpired: "Registration Link Expired",
  catalogSharedClicked: "Share Clicked",
  catalogSharedUrlCopied: "Share URL Copied",
  catalogViewedOnlineClicked: "View Catalog Clicked",
  catalogCreateClicked: "Create Catalog Clicked",
  catalogEditClicked: "Edit Catalog Clicked",
  catalogEdited: "Catalog Edited",
  catalogCreated: "Catalog Created",
  catalogDeleted: "Catalog Deleted",
  helpCenterClicked: "Help Center Clicked",
  rfqCopyEmailClicked: "RFQ Copy Email Clicked",
  usersInvited: "Users Invited",
  shareEmailSent: "Share Email Sent",
  shareMethodSelected: "Share Method Selected",
  supportTicketSubmitted: "Support Ticket Submitted",
  qrCodeDownloaded: "QR Code Downloaded",
  createVariantClicked: "Create Variant Clicked",
  variantGroupCreated: "Variant Group Created",
  variantGroupDeleted: "Variant Group Deleted",
  variantGroupEdited: "Variant Group Edited",
  createProductClicked: "Create Product Clicked",
};

export const catalogSegmentEvents = {
  pdfDownloadClicked: "PDF Download Clicked",
  pdfDownloadStarted: "PDF Download Started",
  productsRemoved: "Products Removed",
  productsAdded: "Products Added",
  catalogStatusChanged: "Catalog Status Changed",
};

export const customAttributeSegmentEvents = {
  createCustomAttributeClicked: "Create Custom Attribute Clicked",
  customAttributeCreated: "Custom Attribute Created",
  attributeTabClicked: "Attribute Tab Clicked",
  browseAndCreateClicked: "Browse & Create Clicked",
  customAttributeEdited: "Custom Attribute Edited",
  librarySearched: "Library Searched",
  libraryAttributeAdded: "Library Attribute Added",
};

export const productManagementSegmentEvents = {
  createProductClicked: "Create Product Clicked",
  editProductClicked: "Edit Product Clicked",
  productColumnEdited: "Product List Column Edited",
  productColumnSearch: "Product Column Searched",
  productCreated: "Product Created",
  productDeleted: "Products Deleted",
  productEdited: "Product Edited",
  productSearched: "Products Searched",
  productInlineEdited: "Inline Product Edited",
  viewInCatalogClicked: "View in EC Clicked",
  productChangeHistoryClicked: "Change History Clicked",
  productChangeHistoryDownloaded: "Change History Downloaded",
  mappingConfirmed: "Mapping Confirmed",
  batchUploadClicked: "Batch Upload Clicked",
};

export const productEditSegmentEvents = {
  clearValueClicked: "Clear Value Clicked",
};

export const companyProfileSegmentEvents = {
  companyProfileEdited: "Company Profile Edited",
};

export const sourcingOpportunitiesSegmentEvents = {
  learnMoreClicked: "Learn More Clicked",
  joinNowClicked: "Join Now Clicked",
  viewProductsClicked: "View Products Clicked",
  submitClicked: "Submit Clicked",
  reviewDisplayed: "Review Displayed",
};

export const gettingStartedSegmentEvents = {
  viewAttributesClicked: "View Attributes Clicked",
  viewProductsClicked: "View Products Clicked",
  viewCompanyProfileClicked: "View Company Profile Clicked",
  viewCatalogsClicked: "View Catalogs Clicked",
  learnMoreClicked: "Learn More Clicked",
  viewSampleCatalogClicked: "View Sample Catalog Clicked",
};

export const localizationSegmentEvents = {
  addLocalizationClicked: "Add Localization Clicked",
  localizeClicked: "Localize Clicked",
  productLocalized: "Product Localized",
  viewLocalizeProductClick: "View Localized Product Clicked",
  productListDisplayed: "Locale Product List Displayed",
  editProductClicked: "Edit Localized Product Clicked",
  columnSearch: "Localized Product Column Searched",
  productDeleted: "Localized Product Deleted",
  productEdited: "Localized Product Edited",
  productInlineEdited: "Localized Inline Product Edited",
  variantCreateButtonClicked: "Localized Create Variant Clicked",
  variantGroupEdited: "Localized Variant Edited",
  variantGroupDeleted: "Localized Variant Deleted",
  variantGroupCreated: "Localized Variant Created",
  newLocalizationClicked: "New Localization Clicked",
  localizationLimitReached: "Localization Limit Reached",
  batchLocalizatonStarted: "Batch Localization Started",
  localizationTaskLogClicked: "Localization Task Log Clicked",
  retryLocalizationClicked: "Retry Localization Clicked",
};

export const smartUploadSegmentEvents = {
  fileUploaded: "File Uploaded",
  uploadCSVGuideClicked: "Upload CSV Guide Clicked",
  sendMessageClicked: "Send Message Clicked",
  exitPageClicked: "Exit Page Clicked",
  headerRowConfirmed: "Header Row Confirmed",
  dataTabClicked: "Data Tab Clicked",
  goToIssueClicked: "Go To issue Clicked",
  skipIssueClicked: "Skip issue Clicked",
  dataConfirmed: "Data Confirmed",
  dataAnalized: "Data Analized",
  productsImported: "Products Imported",
  bulkImageUploadSkipped: "Bulk Image Upload skipped",
  bulkImageUploadClicked: "Bulk Image Upload clicked",
  productSelected: "Product Selected",
  filesDropped: "Files Dropped",
  filesUploaded: "Files Uploaded",
};

export const enrichmentSegmentEvents = {
  enrichmentInitiated: "Enrichment Initiated",
  enrichmentTaskClicked: "Task Clicked",
  talkToSalesClicked: "Talk-to-Sales Clicked",
  imageScrapingInitiated: "Image Scraping Initiated",
  imageScrapingTaskClosed: "Task Closed",
  imageScrapingReviewed: "Scraped Images Reviewed",
  AIOutputApproved: "AI Output Approved",
  AIOutputRejected: "AI Output Rejected",
  AIOutputRejectedAndCleared: "AI Output Rejected and Cleared",
};

export const exportProductSegmentEvents = {
  exportProductsClicked: "Export Products Clicked",
  localizedExportProductsClicked: "Localized Export Products Clicked",
  exportInitiated: "Export Initiated",
  localizeExportInitiated: "Localize Export Initiated",
  downloadFileClicked: "Download File Clicked",
  channelExportInitiated: "Channel Export Initiated",
};

export const channelDistributionSegmentEvents = {
  activateChannelClick: "Activate Channel Clicked",
  talkToSalesClicked: "Talk-to-Sales Clicked",
  configureClicked: "Configure Clicked",
  mappingEdited: "Mapping Edited",
  downloadFileClicked: "Download File Clicked",
  productsAddedToChannel: "Products Added to Channel",
  productsRemovedFromChannel: "Products Removed from Channel",
};

export const damSegmentEvents = {
  toolClicked: "Tool Clicked",
  talkToSalesClicked: "Talk-to-Sales Clicked",
  imagesAdded: "Images Added",
  imagesProcessed: "Images Processed",
  imagesDownloaded: "Images Downloaded",
  imagesDeleted: "Images Deleted",
  imagesClicked: "Images Clicked",
};
