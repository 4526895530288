import { ReactNode } from "react";
import { TaxonomyCascaderOption, TaxonomyTree } from "../types/categories";
import { TaxonomyIdEnum } from "../enums/category";

/**
 * @deprecated Will be replaced by taxonomy APIs.
 */
interface TreeNodeLegacy {
  value: number | string;
  children?: TreeNodeLegacy[];
}

export interface TreeNode {
  label: string;
  value: string;
  children?: TreeNode[];
}

/**
 *
 * @deprecated Will be replaced by new taxonomy APIs util - `buildTreeMapPath`
 */
export const buildTreePathLegacy = (
  node: TreeNodeLegacy,
  path: (number | string)[],
  categoryTreePath: { [key: number | string]: (number | string)[] }
) => {
  const { value, children } = node;
  const currentPath = [...path, value];
  categoryTreePath[value] = currentPath;
  if (children && children.length > 0) {
    children.forEach((node) =>
      buildTreePathLegacy(node, currentPath, categoryTreePath)
    );
  }
};

export const searchTree = <
  T extends { value: string | number; children?: T[] },
>(
  tree: T,
  node: number | string
): T | null => {
  let result = null;
  if (node === tree.value) {
    return tree;
  }
  if (tree?.children) {
    tree.children.some((n) => {
      result = searchTree(n, node);
      return result;
    });
  }
  return result;
};

export const searchTreeLabel = (search: string, label: ReactNode) =>
  `${label}`.toLowerCase().indexOf(search.toLowerCase()) >= 0;

export const buildTreeMapPath = (
  node: TreeNode,
  path: TreeNode[],
  categoryTreePath: { [key: number | string]: TreeNode[] }
) => {
  const { value, children } = node;
  const currentPath = [...path, node];
  categoryTreePath[value] = currentPath;

  if (children && children.length > 0) {
    children.forEach((node) =>
      buildTreeMapPath(node, currentPath, categoryTreePath)
    );
  }
};

export const getCustomCategoryTaxonomyId = (supplierId: string) =>
  `${TaxonomyIdEnum.CUSTOM_MAIN_CATEGORY}_${supplierId}`;

export enum TreeNodeSelectionConfig {
  LEAF_ONLY = "LEAF_ONLY",
  ALL = "ALL",
}

export const configureSelectableTreeNodes = <T extends { children?: T[] }>(
  options: T[],
  config: TreeNodeSelectionConfig
): T[] =>
  options.map((item) => {
    switch (config) {
      case TreeNodeSelectionConfig.LEAF_ONLY: {
        if (item.children) {
          return {
            ...item,
            selectable: false,
            children: configureSelectableTreeNodes(item.children, config),
          };
        }
        return item;
      }

      default: {
        return item;
      }
    }
  });

export const mapping2Options = (c: TaxonomyTree): TaxonomyCascaderOption => ({
  label: c.name,
  value: c.id,
  children:
    c.children && c.children.length > 0
      ? c.children.map((item) => mapping2Options(item))
      : undefined,
});
