import { ImageFileFormat, ImageMimeType, VideoMimeType } from "../enums/file";

export const KILO_PREFIX = 1024;
export const MEGA_PREFIX = 1048576;
export const DEFAULT_STATIC_ASSET_SIZE = 5242880; // 5MB
export const DEFAULT_VIDEO_SIZE = 31457280; // 30MB
export const MAX_UPLOAD_FILE_SIZE = 31457280; // 30MB in binary bytes
export const MAX_MEDIA_UPLOAD_FILE_SIZE = 52428800; // 50MB in binary bytes
export const MAX_DOCUMENT_CERTIFICATION_UPLOAD = 104857600; // 100MB
export const MAX_FILES_TOTAL = 50;
export const MAX_FILES_PER_UPLOAD = 20;

export const FileTypeMapping: Record<
  ImageMimeType | VideoMimeType,
  ImageFileFormat | string
> = {
  [ImageMimeType.PNG]: ImageFileFormat.PNG,
  [ImageMimeType.JPG]: ImageFileFormat.JPG,
  [ImageMimeType.JPEG]: ImageFileFormat.JPEG,
  [ImageMimeType.GIF]: ImageFileFormat.GIF,
  [ImageMimeType.TIFF]: ImageFileFormat.TIFF,
  [ImageMimeType.WEBP]: ImageFileFormat.WEBP,
  [VideoMimeType.AVI]: "AVI",
  [VideoMimeType.MOV]: "MOV",
  [VideoMimeType.MP4]: "MP4",
};

export const CommonFileExtensions = [
  "jpeg",
  "jpg",
  "png",
  "webp",
  "tiff",
  "gif",
  "mp4",
  "avi",
  "mov",
  "xlsx",
  "xls",
  "csv",
];
