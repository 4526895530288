import { CoreCategoryTree } from "@/src/enums/category";
import useAppContext from "../components/appContext/useAppContext";
import { getCustomCategoryTaxonomyId } from "../utils/category";

export function useCategoryTree() {
  const { ctx } = useAppContext();

  const coreCategoryTree =
    ctx.user?.coreCategoryTree ?? CoreCategoryTree.GOOGLE;

  const taxonomyId = ctx.user?.supplierId
    ? getCustomCategoryTaxonomyId(ctx.user?.supplierId)
    : null;

  const enableGoogleCategory = [
    CoreCategoryTree.GOOGLE,
    CoreCategoryTree.TRANSITION,
  ].includes(coreCategoryTree);

  const enableCustomCategory = [
    CoreCategoryTree.TRANSITION,
    CoreCategoryTree.CUSTOM,
  ].includes(coreCategoryTree);

  return {
    taxonomyId,
    coreCategoryTree,
    enableGoogleCategory,
    enableCustomCategory,
  };
}
