// List of folders in S3 bucket
export enum FileUploadTagEnum {
  COMPANY_IMAGE = "COMPANY_IMAGE",
  PRODUCT_IMAGE = "PRODUCT_IMAGE",
  COMPANY_DOC = "COMPANY_DOC",
  PRODUCT_IMPORT = "PRODUCT_IMPORT",
  PRODUCT_EXPORT = "PRODUCT_EXPORT",
}

export enum ImageFileFormat {
  JPG = "JPG",
  PNG = "PNG",
  JPEG = "JPEG",
  WEBP = "WEBP",
  GIF = "GIF",
  TIFF = "TIFF",
}

export enum ImageMimeType {
  PNG = "image/png",
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  WEBP = "image/webp",
  GIF = "image/gif",
  TIFF = "image/tiff",
}

export enum VideoMimeType {
  MP4 = "video/mp4",
  AVI = "video/x-msvideo",
  MOV = "video/quicktime",
}

export enum ExcelMimeType {
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  CSV = "text/csv",
}

export enum ExcelFileFormat {
  CSV = "CSV",
  XLSX = "XLSX",
}

export enum CsvSeparatorEnum {
  DOUBLE_QUOTE = '"',
  SINGLE_QUOTE = "'",
}

export enum CsvDelimiterEnum {
  BREAK_LINE = "\n",
  COMMA = ",",
  SEMICOLON = ";",
  PIPE = "|",
  SLASH = "/",
  GREATER_THAN = ">",
}

export enum EncodingEnum {
  UTF8 = "UTF-8",
}

export enum OtherMimeType {
  PDF = "application/pdf",
}

export enum FileValidationErrorCode {
  SUCCESS = 0,
  INVALID_RESOLUTION = 610000,
  INVALID_FILE_FORMAT = 610001,
  INVALID_FILE_SIZE = 610002,
}
