export enum CoreCategoryTree {
  GOOGLE = "GOOGLE_CATEGORY",
  CUSTOM = "CUSTOM",
  TRANSITION = "TRANSITION",
}

export enum TaxonomyIdEnum {
  GOOGLE_CATEGORY = "GOOGLE_CATEGORY",
  LAZADA_CATEGORY = "LAZADA_CATEGORY",
  SHOPEE_CATEGORY = "SHOPEE_CATEGORY",
  CUSTOM_MAIN_CATEGORY = "CUSTOM_MAIN_CATEGORY",
}
